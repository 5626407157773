import { useField, useFieldObserver } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getColor, statesClosed, statesOngoing } from 'router/tickets/utils';

export default function SectionPicker({ path, label, color = 'green' }) {
  const { t } = useTranslation();
  const isChecked = useFieldObserver(path);
  const { setValue: setIsChecked } = useField(path);
  const { activeIconColor, inactiveIconColor, activeTextColor, inactiveBorderColor, activeBorderColor } =
    getColor(color);

  const { value: status, setValue: setStatus } = useField('status');
  const ongoing = useFieldObserver('ongoing');
  const closed = useFieldObserver('closed');

  const onCheck = () => {
    const newCheckValue = !isChecked;
    const newOngoing = path === 'ongoing' ? newCheckValue : ongoing;
    const newClosed = path === 'closed' ? newCheckValue : closed;

    // Met à jour les statuts en fonction des nouvelles valeurs d'ongoing et closed
    const updatedStatus = (() => {
      if (status) {
        if (newOngoing && newClosed) return [...new Set([...status, ...statesOngoing, ...statesClosed])];
        if (newOngoing) return [...new Set([...status.filter((s) => !statesClosed.includes(s)), ...statesOngoing])];
        if (newClosed) return [...new Set([...status.filter((s) => !statesOngoing.includes(s)), ...statesClosed])];
        return status.filter((s) => !statesOngoing.includes(s) && !statesClosed.includes(s));
      }
    })();

    setStatus(updatedStatus);
    setIsChecked(newCheckValue);
  };

  return (
    <button
      onClick={onCheck}
      className={`cursor-pointer border ${isChecked ? activeBorderColor : inactiveBorderColor}  
      ${
        isChecked ? activeTextColor : ''
      } inline-flex text-gray-500 items-center pl-2 pr-3 h-8 rounded-full text-xs font-medium hover:bg-gray-50 transition`}
    >
      <FAIcon
        collection={isChecked ? 'fas' : 'fal'}
        icon={isChecked ? 'circle-check' : 'circle'}
        size="small"
        className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
      />
      <span className="whitespace-nowrap">{t(label)}</span>
    </button>
  );
}
