export const transformAlertExceeded = (key, value) => {
  if (!value) return [];

  return [{ key: 'alert_at', val: String(new Date().getTime()), op: 'lt' }];
};

export const transformMyInterventions = (key, value, auth) => {
  if (!value) return [];

  return [{ key: 'managers', val: [auth.user._id], op: 'in' }];
};

export const transformHasContractor = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_contractor', val: false, op: 'equals' }];
};

export const transformHasManagers = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_managers', val: false, op: 'equals' }];
};

export const transformHasSkills = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_skills', val: false, op: 'equals' }];
};

export const transformSpecialType = (key, value) => {
  const prefix = key.slice(0, -1); // "quote" ou "invoice"
  const results = [];

  // Ajouter has_<prefix> seulement si "with" ou "without" est activé, mais pas les deux
  if (value.with && !value.without) {
    results.push({ key: `has_${prefix}`, val: true, op: 'equals' });
  } else if (!value.with && value.without) {
    results.push({ key: `has_${prefix}`, val: false, op: 'equals' });
    return results;
  }

  if (value.amount && Array.isArray(value.amount.values)) {
    const [min, max] = value.amount.values;
    if (min !== null && max !== null && value.amount.op === 'range') {
      results.push({ key: `${prefix}_amount`, val: [min, max], op: 'range' });
    } else if (min !== null && value.amount.op !== 'range') {
      const nonNullValue = min !== null ? min : max;
      results.push({ key: `${prefix}_amount`, val: nonNullValue, op: value.amount.op });
    }
  }

  if (value.validatedBy && Array.isArray(value.validatedBy)) {
    const ids = value.validatedBy.map((item) => item._id || item); // Extraire `_id` si objets
    if (ids.length > 0) {
      results.push({ key: `${prefix}_validated_by`, val: ids, op: 'in' });
    }
  }

  if (value.reference && value.reference.trim()) {
    results.push({ key: `${prefix}_reference`, val: value.reference.trim(), op: 'equals' });
  }

  if (value.validated) {
    results.push({ key: `validated_${prefix}`, val: value.validated, op: 'equals' });
  }

  if (value.noValidated) {
    results.push({ key: `unvalidated_${prefix}`, val: value.noValidated, op: 'equals' });
  }

  return results;
};

export const transformStatus = (states) => (key, value) => value ? [{ key: 'status', val: states, op: 'in' }] : [];

// transform bookmarks
// exemple : [key:"bookmarks", val:["toplan", "toreplan"], op:"in"]
// expected result:
// [{key:"status", val:["toplan", "toreplan"], op:"in"}]
export const transformBookmarks = (key, values, auth) => {
  const results = [];

  const conditionsMap = {
    toplan: [{ key: 'status', val: ['toplan'], op: 'in' }],
    toreplan: [{ key: 'status', val: ['toreplan'], op: 'in' }],
    waitingPlan: [{ key: 'status', val: ['waitingPlan'], op: 'in' }],
    opened: [{ key: 'status', val: ['opened'], op: 'in' }],
    waiting: [{ key: 'status', val: ['waiting', 'proposed'], op: 'in' }],
    validation: [
      { key: 'has_unvalidated_quote', val: true, op: 'equals' },
      { key: 'has_quote_children', val: auth?.interface?.isContractor || false, op: 'equals' }
    ],
    validation_waiting: [
      { key: 'has_unvalidated_quote', val: true, op: 'equals' },
      { key: 'has_quote_children', val: false, op: 'equals' }
    ],
    validation_plan: [{ key: 'status', val: ['validated'], op: 'in' }],
    assigned: [{ key: 'status', val: ['assigned', 'visit_devis', 'intervention_finish'], op: 'in' }],
    visit: [{ key: 'status', val: ['visit', 'intervention'], op: 'in' }],
    finished: [{ key: 'status', val: ['finished'], op: 'in' }]
  };

  // Transform bookmarks based on conditionsMap
  values.forEach((bookmark) => {
    if (conditionsMap[bookmark]) {
      results.push(...conditionsMap[bookmark]);
    }
  });

  return results;
};

export const transformReports = (key, value) => {
  const results = [];

  if (value.ir?.with && !value.ir?.without) {
    results.push({ key: 'has_report', val: true, op: 'equals' });
  } else if (!value.ir?.with && value.ir?.without) {
    results.push({ key: 'has_report', val: false, op: 'equals' });
  }

  if (value.reserve?.with && !value.reserve?.without) {
    results.push({ key: 'has_report_reserve', val: true, op: 'equals' });
  } else if (!value.reserve?.with && value.reserve?.without) {
    results.push({ key: 'has_report_reserve', val: false, op: 'equals' });
  }

  return results;
};

export const transformValidedPeriod = (key, value) => {
  const results = [];

  if (value?.with && !value?.without) {
    results.push({ key: 'has_validated_period', val: true, op: 'equals' });
  } else if (!value?.with && value?.without) {
    results.push({ key: 'has_validated_period', val: false, op: 'equals' });
  }

  return results;
};

export const transformCustomFields = (values) => {
  // values = [{_field: "field_id", value: ["value1", "value2"], op: "in"}]
  const results = [];

  if (values?.length) {
    values.forEach((value) => {
      results.push({ key: value._field, val: value.value, op: value.op || 'equals' });
    });
  }

  return results;
};

export const transformDates = (key, value) => {
  const results = [];

  Object.entries(value).forEach(([dateKey, dateValue]) => {
    if (dateValue?.values) {
      const [min, max] = dateValue.values;

      if (min !== null && max !== null && dateValue.op === 'range') {
        results.push({ key: convertToSnakeCase(dateKey), val: [Date.parse(min), Date.parse(max)], op: 'range' });
      } else if (min !== null && dateValue.op !== 'range') {
        const singleValue = min !== null ? min : max;
        results.push({ key: convertToSnakeCase(dateKey), val: Date.parse(singleValue), op: dateValue.op });
      }
    }
  });

  return results;
};

const convertToSnakeCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

// exemple:
// before quotes:
// {"key":"quotes","val":{"with":true,"without":true,"amount":{"values":[23,null],"op":"equals"},"reference":"","validated":false,"noValidated":false,"validatedBy":[]},"op":"object"}
// expected result:
// {key:"has_quote", val:true, op:"equals"}
// {key:"quote_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"quote_amount", val:"23", op:"equals"}
// {key:"quote_validated_by", val:["user_id"], op:"in"}
// {key:"quote_reference", val:"#234sfsjdk234", op:"equals"}
// {key:"has_unvalidated_quote", val:true, op:"equals"}

// exemple 2:
// before quotes:
// {"key":"invoices","val":{"with":true,"without":true,"amount":{"values":[12,null],"op":"equals"},"reference":""},"op":"object"}
// expected result:
// {key:"has_invoice", val:"true", op:"equals"}
// {key:"invoice_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"invoice_amount", val:"12", op:"equals"}
// {key:"invoice_reference", val:"#234sfsjdk234", op:"equals"}

// exemple 3:
// before reports:
// {"key":"reports","val":{"ir":{"with":true,"without":false},"reserve":{"with":true,"without":false}},"op":"object"}
// expected result:
// {key:"has_report", val:true, op:"equals"}
// {key:"has_reserve", val:true, op:"equals"}

// exemple 4:
// before dates:
// {"key":"dates","val":{"creationAt":{"values":[12,null],"op":"gt"},"flagFinished":{"values":[12,null],"op":"gte"},"interventionDate":{"values":[12,46],"op":"range"},"visitDate":{"values":'12',"op":"lte"}},"op":"object"}
// expected result:
// {key:"creation_at", val:'12', op:"gt"}
// {key:"flag_finished", val:'12', op:"gte"}
// {key:"intervention_date", val:[12, 46], op:"range"}
// {key:"visit_date", val:'12', op:"lte"}

// exemple 5:
// before customFields:
// {"key":"customFields","val":[{"field":"field_id","value":["value1","value2"],"op":"in"}],"op":"object"}
// expected result:
// {key:"field_id", val:["value1", "value2"], op:"in"}

// exemple 6:
// before validatedPeriod:
// {"key":"validatedPeriod","val":{"with":true,"without":true},"op":"object"}
// expected result:
// {key:"validated_period", val:true, op:"equals"}
