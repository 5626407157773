import {
  transformAlertExceeded,
  transformBookmarks,
  transformCustomFields,
  transformDates,
  transformHasContractor,
  transformHasManagers,
  transformHasSkills,
  transformMyInterventions,
  transformReports,
  transformSpecialType,
  transformStatus,
  transformValidedPeriod
} from './Filters/transformFilters';

export const allStates = [
  'visit_devis',
  'validation',
  'validated',
  'dropped',
  'intervention',
  'intervention_finish',
  'finished',
  'closed',
  'canceled',
  'cashedby',
  'removed',
  'visit',
  'assigned',
  'waiting_contractor',
  'waitingplan',
  'toplan',
  'toreplan',
  'proposed',
  'waiting',
  'opened',
  'draft',
  'expired',
  'accepted',
  'declined',
  'created'
];

export const statesOngoing = [
  'draft',
  'opened',
  'waiting',
  'proposed',
  'assigned',
  'visit',
  'validation',
  'validated',
  'intervention',
  'finished'
];
export const statesClosed = ['closed', 'declined'];

const bookmarkColors = {
  toplan: 'orange',
  toreplan: 'red', // lime -> red
  waitingplan: 'black-blue',
  opened: 'slate',
  waiting: 'black-blue', // yellow -> black (a assigner)
  validation: 'orange-clair',
  assigned: 'orange', // pink to -> orange
  visit: 'blue-sky',
  finished: 'green', // teal -> green
  validation_waiting: 'light-orange',
  validation_plan: 'red',
  technicians: 'blue'
};

export const getBookmarks = (scheduler, calendar, auth) => {
  if (scheduler) {
    return [
      { key: 'toplan', color: bookmarkColors.toplan, label: 'toPlan' },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toReplan' },
      { key: 'waitingPlan', color: bookmarkColors.waitingplan, label: 'waitingToBeReplaned' }
    ];
  } else if (calendar) {
    return [{ key: 'reset', color: bookmarkColors.technicians, label: 'allTechnicians' }];
  } else {
    return [
      auth.interface.isClient ? { key: 'opened', color: bookmarkColors.opened, label: 'toValid' } : {},
      { key: 'waiting', color: bookmarkColors.waiting, label: 'toBeAssignated' },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toBeReplaned' },
      { key: 'validation', color: bookmarkColors.validation, label: 'quoteToBeValidated' },
      auth.interface.isContractor
        ? { label: 'quoteAtt', color: bookmarkColors.validation_waiting, key: 'validation_waiting' }
        : {},
      auth.interface.isContractor
        ? { label: 'quotePl', color: bookmarkColors.validation_plan, key: 'validation_plan' }
        : {},
      { key: 'assigned', color: bookmarkColors.assigned, label: 'waitingForInfo' },
      { key: 'visit', color: bookmarkColors.visit, label: 'inProgress' },
      { key: 'finished', color: bookmarkColors.finished, label: 'toBeClosed' }
    ].filter((bookmark) => Object.keys(bookmark).length > 0);
  }
};

export const sortLabel = [
  'creationDateMostRecent',
  'creationDateLeastRecent',
  'updateDateMostRecent',
  'updateDateLeastRecent',
  'ascendingAlphabetical',
  'descendingAlphabetical'
];

export function getColor(color) {
  switch (color) {
    case 'slate':
      return {
        activeIconColor: 'text-[#7f8c8d]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#7f8c8d]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#7f8c8d]',
        activeBackgroundColor: 'bg-[#7f8c8d]'
      };
    case 'red':
      return {
        activeIconColor: 'text-[#ce4413]', //  text-red-500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ce4413]', // 600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ce4413]', //border-red-500
        activeBackgroundColor: 'bg-[#ce4413]' // bg-red-500
      };
    case 'light-red':
      return {
        activeIconColor: 'text-[#e63d53]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#e63d53]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#e63d53]',
        activeBackgroundColor: 'bg-[#e63d53]'
      };
    case 'green':
      return {
        activeIconColor: 'text-[#31b55a]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#31b55a]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#31b55a]',
        activeBackgroundColor: 'bg-[#31b55a]'
      };
    case 'blue':
      return {
        activeIconColor: 'text-blue-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-blue-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-blue-500',
        activeBackgroundColor: 'bg-blue-500'
      };
    case 'blue-sky':
      return {
        activeIconColor: 'text-[#1fadda]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#1fadda]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#1fadda]',
        activeBackgroundColor: 'bg-[#1fadda]'
      };
    case 'yellow':
      return {
        activeIconColor: 'text-yellow-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-yellow-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-yellow-500',
        activeBackgroundColor: 'bg-yellow-500'
      };
    case 'orange':
      return {
        activeIconColor: 'text-[#ee754a]', // 500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ee754a]', // 600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ee754a]',
        activeBackgroundColor: 'bg-[#ee754a]'
      };
    case 'light-orange':
      return {
        activeIconColor: 'text-[#ef7a1f]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ef7a1f]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ef7a1f]',
        activeBackgroundColor: 'bg-[#ef7a1f]'
      };
    case 'pink':
      return {
        activeIconColor: 'text-[#fc427b]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#fc427b]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#fc427b]',
        activeBackgroundColor: 'bg-[#fc427b]'
      };
    case 'teal':
      return {
        activeIconColor: 'text-teal-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-teal-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-teal-500',
        activeBackgroundColor: 'bg-teal-500'
      };
    case 'black-blue':
      return {
        activeIconColor: 'text-[#2c3e50]', // text-500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#2c3e50]', // text--600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#2c3e50]', // border--500
        activeBackgroundColor: 'bg-[#2c3e50]' // bg-500
      };
    case 'indigo':
      return {
        activeIconColor: 'text-indigo-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-indigo-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-indigo-500',
        activeBackgroundColor: 'bg-indigo-500'
      };
    case 'lime':
      return {
        activeIconColor: 'text-lime-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-lime-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-lime-500',
        activeBackgroundColor: 'bg-lime-500'
      };
    case 'purple':
      return {
        activeIconColor: 'text-[#8e44ad]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#8e44ad]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#8e44ad]',
        activeBackgroundColor: 'bg-[#8e44ad]'
      };
    case 'dark-gray':
      return {
        activeIconColor: 'text-[#4f5b62]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#4f5b62]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#4f5b62]',
        activeBackgroundColor: 'bg-[#4f5b62]'
      };
    case 'sky':
      return {
        activeIconColor: 'text-sky-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-300',
        activeTextColor: 'text-sky-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-sky-500',
        activeBackgroundColor: 'bg-sky-500'
      };
    default:
      return {
        activeIconColor: 'text-amber-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-amber-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-amber-500',
        activeBackgroundColor: 'bg-amber-500'
      };
  }
}

const getDefaultFilterEntry = (key, value) => ({
  key,
  val:
    Array.isArray(value) && value.every((item) => typeof item === 'object' && item._id)
      ? value.map((item) => item._id)
      : value,
  op: key === 'search' ? 'fuzzy' : Array.isArray(value) ? 'in' : typeof value === 'object' ? 'object' : 'equals'
});

// function which transform { key: value, ... } to [{ key: "key", value: "value", op:"op"}, ...] <-- for UI
export const normalizeFilters = ({ filters }) => {
  if (Array.isArray(filters)) return filters;

  return Object.entries(filters).map(([key, value]) => getDefaultFilterEntry(key, value));
};

// function which transform { key: value, ... } to [{ key: "key", value: "value", op:"op"}, ...] <-- for API
export const prepareFiltersForApi = ({ filters, auth }) => {
  if (Array.isArray(filters)) return filters;

  // Combine `clients` and `contractors` into `companies`
  if (filters.clients || filters.contractors) {
    filters.companies = [...(filters.clients || []), ...(filters.contractors || [])];
  }

  // Merge domains and subDomains
  if (filters.subDomains || filters.domains) {
    filters.domains = [...(filters.subDomains || []), ...(filters.domains || [])];
  }

  if (filters.bookmarks.length) {
    filters.status = [];
    filters.ongoing = false;
    filters.closed = false;
  }

  const specialHandlers = {
    quotes: transformSpecialType,
    invoices: transformSpecialType,
    reports: transformReports,
    dates: transformDates,
    ongoing: transformStatus(statesOngoing),
    closed: transformStatus(statesClosed),
    bookmarks: transformBookmarks,
    validatedPeriod: transformValidedPeriod,
    customFields: transformCustomFields,
    has_contractor: transformHasContractor,
    has_skills: transformHasSkills,
    has_managers: transformHasManagers,
    alertExceeded: transformAlertExceeded,
    myInterventions: transformMyInterventions
  };

  const result = Object.entries(filters)
    .filter(([, value]) => isValidValue(value))
    .flatMap(([key, value]) =>
      specialHandlers[key] ? specialHandlers[key](key, value, auth) : [getDefaultFilterEntry(key, value)]
    );

  // remove client and contractor and subDomains
  result.filter((filter) => filter.key !== 'clients' && filter.key !== 'contractors' && filter.key !== 'subDomains');

  // Merge filters with `op: 'in'` for `status` and `managers`
  const mergeFiltersByKey = (key) =>
    result
      .filter((filter) => filter.key === key && filter.op === 'in')
      .reduce(
        (acc, filter) => {
          acc.val.push(...filter.val);
          return acc;
        },
        { key, val: [], op: 'in' }
      );

  const mergedStatusFilter = mergeFiltersByKey('status');
  const mergedManagersFilter = mergeFiltersByKey('managers');

  // Remove duplicate values from the merged filters
  mergedStatusFilter.val = [...new Set(mergedStatusFilter.val)];
  mergedManagersFilter.val = [...new Set(mergedManagersFilter.val)];

  // Add all non-status and non-managers filters and the merged filters (if applicable)
  return [
    ...result.filter((filter) => !((filter.key === 'status' || filter.key === 'managers') && filter.op === 'in')),
    ...(mergedStatusFilter.val.length ? [mergedStatusFilter] : [{ key: 'status', val: ['teapot_status'], op: 'in' }]),
    ...(mergedManagersFilter.val.length ? [mergedManagersFilter] : [])
  ];
};

// Helper function to check if a value is valid (excluding undefined, null, empty strings, and empty arrays)
const isValidValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0; // Exclure les tableaux vides
  }
  return value !== undefined && value !== null && value !== '';
};

// Function to transform [{ key: "key", value: "value", op: "op" }, ...] back to { key: value, ... }
export const transformNewSystemToOld = ({ filters }) => {
  if (!Array.isArray(filters)) return filters;

  const result = {};

  filters.forEach(({ key, val, op }) => {
    // Si la valeur est un tableau et l'opérateur est 'in', transformer en objets avec _id
    if (Array.isArray(val) && op === 'in') {
      result[key] = val.map((id) => ({ _id: id }));
    } else {
      // Sinon, assigner la valeur directement
      result[key] = val;
    }
  });

  return result;
};
