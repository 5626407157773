import axios from 'axios';
import useDebouncedState from 'hooks/useDebouncedState';
import { apiBaseURL } from 'index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useNotifications from 'hooks/useNotifications';
import Document from './Document';
import Skeleton from './Skeleton';
import FolderPath from './FolderPath';
import Portal from 'components/Portal';
import ContextFileMenu from './contextMenu/ContextMenu';
import Trash from './Trash';
import { nanoid } from 'nanoid';
import CreateFolderDialog from './dialogs/FolderNew';
import RenameDocumentDialog from './dialogs/DocumentRename';
import DocumentEditor from './dialogs/DocumentEditor';
import DocumentInfo from './dialogs/DocumentInfo';
import { pick } from 'lodash-es';
import DocumentComments from './dialogs/DocumentComments';
import useFileViewer from 'hooks/useFileViewer';
import { useRole } from 'hooks/useRole';
import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import EntityPopoverFilter from 'components/filters/EntityPopoverFilter';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import MenuItem from 'components/menu/MenuItem';
import ListPopoverFilter from 'components/filters/ListPopoverFilter';
import { useSelector } from 'react-redux';
import UploadButton from './UploadButton';
import FAIcon from 'components/ui/FAIcon';
import DropZone from 'components/DropZone';
import InfiniteLoader from 'components/InfiniteLoader';
import SectionPicker from 'components/filters/SectionPicker';
import { can_preview_file } from './utils';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { getElement } from 'store/ticketsSlice';
import { useEntity } from 'contexts/entities/entityContext';
import Client from 'entities/Client/Client';
import { useAuth } from 'hooks/useAuth';
import Intervener from 'entities/Intervener/Intervener';
import { Fab } from '@material-ui/core';

export default function Documents({ endpoint_prefix = 'ged', tab_id, with_filters = false, hiddenFilters }) {
  const initialFilterValues = useMemo(() => {
    return {
      search: '',
      preventifs: null,
      interveners: null,
      locations: null,
      equipments: null,
      companies: null,
      tag_ids: [],
      without_tag_ids: [],
      to_monitor: false,
      has_expired: false
    };
  }, []);
  // Keep track of any ongoing async action occuring
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingNFiles, setUploadingNFiles] = useState(0);
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false);
  // The highlighted document
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  // The documents that will be displayed
  const [documents, setDocuments] = useState(null);
  // Keep track of the path to the current folder
  const [folderPath, setFolderPath] = useState([]);
  // Keep track of whether we're looking at deleted documents or not
  const [viewTrash, setViewTrash] = useState(false);
  const [trash_is_empty, set_trash_is_empty] = useState(false);
  // Keep track of whether the dialog to create a folder by naming it is open
  const [folderNameDialogIsOpen, setFolderNameDialogIsOpen] = useState(false);
  // Keep track of the document selected to be renamed and whehter the dialog to rename it is open
  const [documentToRename, setDocumentToRename] = useState(null);
  // Keep track of whether the dialog to edit a document is open and for which document
  const [documentToEdit, setDocumentToEdit] = useState(null);
  // Keep track of whether the dialog to display a document's infos is open and for which document
  const [documentToConsult, setDocumentToConsult] = useState(null);
  // Keep track of whether the dialog to display a document's comments is open and for which document
  const [documentCommentsToConsult, setDocumentCommentsToConsult] = useState(null);
  // The context menu data containing the position and the document if applicable
  const [contextMenuData, setContextMenuData] = useState(null);
  // Keep track of the documents total count
  const [documentsTotalCount, setDocumentsTotalCount] = useState(0);
  // Keep track of the filters applied when fetching documents
  const [_filters, setFilters, debouncedFilters] = useDebouncedState({
    search: '',
    preventifs: null,
    interveners: null,
    locations: null,
    equipments: null,
    tag_ids: [],
    without_tag_ids: [],
    to_monitor: false,
    has_expired: false
  });

  const scrollableContainerRef = useRef(null);

  const role = useRole();
  const tag_facture_id = '632196807da0efb412092d63';
  const tag_devis_id = '632196677da0efb412092d62';
  const without_tag_ids = [];
  !role.permission('tickets', 'display_facture') && without_tag_ids.push(tag_facture_id);
  !role.permission('tickets', 'display_devis') && without_tag_ids.push(tag_devis_id);

  const canEditAndDeleteDocuments = useMemo(() => role.permission('documents', 'document-owner'), []);
  const read_only_mode = !tab_id || !canEditAndDeleteDocuments;
  const auth = useAuth();

  let { tags } = useSelector((state) => state.tags);
  tags = tags.filter((tag) => !without_tag_ids.includes(tag._id));

  const filters_in_bar = useMemo(
    () => [
      <ListPopoverFilter
        label="tagsTitle"
        options={tags}
        path="tag_ids"
      />,
      <EntityPopoverFilter
        label="locations"
        entity={Location}
        path="locations"
      />,
      <EntityPopoverFilter
        label="equipmentsTitle"
        entity={Equipment}
        path="equipments"
      />,

      // Filter companies
      auth?.interface?.isClient ? (
        <EntityPopoverFilter
          label="societiesLabel"
          entity={Intervener}
          path="companies"
        />
      ) : (
        <EntityPopoverFilter
          label="societiesLabel"
          entity={Client}
          path="companies"
        />
      ),
      <SectionPicker
        path="to_monitor"
        label="toMonitor"
        color="orange"
      />,
      <SectionPicker
        path="has_expired"
        label="expired"
        color="red"
      />
    ],
    [tags]
  );

  const trashId = useMemo(() => nanoid(), []);

  const { dispatch } = useAsyncDispatch();
  const { openFiles } = useFileViewer();
  const notify = useNotifications();
  const entity_ctx = useEntity();

  const openFile = useCallback(
    (idx = 0) => {
      const filtered_docs = documents?.filter((doc) => can_preview_file(doc));
      const document_id = documents?.[idx]?._id;
      const new_document_idx = filtered_docs?.findIndex((doc) => doc._id === document_id);
      can_preview_file(documents[idx]) && openFiles(filtered_docs, new_document_idx);
    },
    [documents]
  );

  // * Function Array.prototype.at() is not supported by older versions of browsers
  // * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at#browser_compatibility
  const currentFolderId = useMemo(() => folderPath[folderPath.length - 1]?._id || '', [folderPath]);

  const goToFolder = useCallback((folder) => {
    setFolderPath((fp) => [...fp, folder]);
  }, []);

  const getDocuments = async ({ skip = 0 } = {}) => {
    if (skip === 0) {
      scrollableContainerRef.current?.scrollTo({ top: 0 });
    }
    setIsLoading(true);

    const format_filters = (filters) => {
      const { search, to_monitor, has_expired, ...rest } = filters;

      for (const key in rest) {
        if (rest[key]) {
          rest[key] = rest[key].map((e) => e._id);
        }
      }

      // add without_tag_ids in key without_tag_ids
      if (without_tag_ids.length > 0) {
        rest.without_tag_ids = without_tag_ids;
      }

      return {
        search: search?.trim(),
        to_monitor,
        has_expired,
        ...rest
      };
    };

    const { search, to_monitor, has_expired, ...metadata } = format_filters(debouncedFilters);

    const endpoint =
      tab_id !== null ? `${apiBaseURL}/${endpoint_prefix}/tab/${tab_id}` : `${apiBaseURL}/${endpoint_prefix}`;

    return await axios
      .get(endpoint, {
        params: {
          search,
          to_monitor,
          has_expired,
          ...metadata,
          skip,
          parent_id: currentFolderId,
          trash: viewTrash ? true : undefined,
          hiddenFilters
        }
      })
      .then(({ data }) => {
        set_trash_is_empty(data.trash_is_empty);
        setDocumentsTotalCount(data.total_count);
        setDocuments((documents) => (skip > 0 ? [...documents, ...data.documents] : [...data.documents]));
      })
      .catch((err) => {
        notify.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createFolder = useCallback(
    async (name) => {
      setIsLoading(true);
      return await axios
        .post(`${apiBaseURL}/${endpoint_prefix}/${tab_id}/new-folder`, {
          parent_id: currentFolderId,
          name
        })
        .then(({ data }) => {
          setDocuments((docs) => [...docs, data.document]);
          setIsLoading(false);
          return { data };
        })
        .catch(() => {
          setIsLoading(false);
          notify.error();
        });
    },
    [currentFolderId]
  );

  const moveDocument = useCallback(
    async ({ document_id, parent_id }) => {
      setIsLoading(true);
      return await axios
        .put(`${apiBaseURL}/${endpoint_prefix}/mv/${document_id}/${parent_id}`, {})
        .then(() => {
          setDocuments((documents) => documents.filter((document) => document._id !== document_id));
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          notify.error();
        });
    },
    [documents]
  );

  const renameDocument = useCallback(
    async ({ document_id, name }) => {
      setIsLoading(true);
      return await axios
        .put(`${apiBaseURL}/${endpoint_prefix}/rename/${document_id}`, {}, { params: { name } })
        .then(() => {
          setDocuments((docs) =>
            docs.map((doc) => {
              if (doc._id !== document_id) {
                return doc;
              } else {
                return { ...doc, name };
              }
            })
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          notify.error();
        });
    },
    [documents]
  );

  const editDocument = useCallback(
    async (editedDocument) => {
      setIsLoading(true);

      function formatMetadata(metadata, formatters) {
        return Object.keys(metadata).reduce((acc, key) => {
          return {
            ...acc,
            [key]: formatters[key] ? formatters[key](metadata[key]) : metadata[key]
          };
        }, {});
      }

      const formatters = {
        locations: (locations) => locations.map((el) => pick(el, ['_id', 'name'])),
        equipments: (equipments) => equipments.map((el) => pick(el, ['_id', 'name'])),
        interveners: (interveners) =>
          interveners.map((el) => ({
            _id: el._id,
            name: el.name ? el.name : el.firstName ? el.firstName + ' ' + el.lastName : el.companyName
          }))
      };

      editedDocument.metadata = formatMetadata(editedDocument.metadata, formatters);

      return await axios
        .put(`${apiBaseURL}/${endpoint_prefix}/edit/${editedDocument._id}`, editedDocument)
        .then(() => {
          setDocuments((docs) =>
            docs.map((doc) => {
              if (doc._id !== editedDocument._id) {
                return doc;
              } else {
                return { ...doc, ...editedDocument };
              }
            })
          );

          setDocumentToConsult((doc) => ({ ...doc, ...editedDocument }));
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          notify.error();
        });
    },
    [documents]
  );

  const uploadRevision = useCallback(
    async ({ document_id, file }) => {
      if (!file) return;

      setIsLoading(true);
      const data = new FormData();
      data.append('file', file);

      return await axios
        .post(`${apiBaseURL}/${endpoint_prefix}/version/${document_id}`, data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(({ data }) => {
          setDocuments((docs) =>
            docs.map((doc) => {
              if (doc._id !== document_id) {
                return doc;
              } else {
                return { ...doc, ...data.document };
              }
            })
          );
          setIsLoading(false);
          return { data };
        })
        .catch(() => {
          setIsLoading(false);
          notify.error();
        });
    },
    [documents]
  );

  const getRevisions = useCallback(async ({ document_id }) => {
    setIsLoading(true);
    return await axios
      .get(`${apiBaseURL}/${endpoint_prefix}/revisions/${document_id}`)
      .then(({ data }) => {
        setIsLoading(false);
        return { data };
      })
      .catch(() => {
        setIsLoading(false);
        notify.error();
      });
  }, []);

  const uploadFiles = useCallback(
    async (files) => {
      if (!files.length) return;
      setUploadingNFiles(files.length);
      const data = new FormData();

      for (let i = 0; i < files.length; i++) {
        data.append('file', files[i]);
      }

      return await axios
        .post(`${apiBaseURL}/${endpoint_prefix}/upload/${tab_id}`, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { parent_id: currentFolderId }
        })
        .then(({ data }) => {
          setDocuments((documents) =>
            Array.isArray(data?.documents) ? (documents || []).concat(data.documents) : [documents]
          );
          setUploadingNFiles(0);
        })
        .catch(() => {
          setUploadingNFiles(0);
          notify.error();
        });
    },
    [currentFolderId, documents]
  );

  const handleDocumentsAction = async (document_ids, actionType) => {
    setIsLoading(true);

    try {
      // Create the appropriate URL based on the actionType
      const actionUrl = `${apiBaseURL}/${endpoint_prefix}/${actionType}`;

      // Make an array of DELETE/PUT requests for each document ID
      const promises = document_ids.map((document_id) => {
        return axios({
          method: actionType === 'restore' ? 'put' : 'delete',
          url: `${actionUrl}/${document_id}`
        });
      });

      // Wait for all requests to complete
      await Promise.all(promises);

      // Update documents state after all requests are done
      setDocuments((documents) => documents.filter((document) => !document_ids.includes(document._id)));

      // If entity context is present and entity is 'tickets', trigger dispatch
      if (entity_ctx && entity_ctx.entity === 'tickets') {
        dispatch(getElement, {}, {}, { id: entity_ctx?.element?._id });
      }

      set_trash_is_empty(false);
    } catch (err) {
      // Handle error
      notify.error();
    } finally {
      // Set loading state to false whether success or error
      setIsLoading(false);
    }
  };

  // Delete documents
  const deleteDocuments = (document_ids) => handleDocumentsAction(document_ids, 'delete');

  // Trash documents
  const trashDocuments = (document_ids) => handleDocumentsAction(document_ids, 'trash');

  // Restore documents
  const restoreDocuments = (document_ids) => handleDocumentsAction(document_ids, 'restore');

  // Initial fetch
  useEffect(() => {
    getDocuments();
  }, [debouncedFilters, currentFolderId, viewTrash]);

  const downloadDocuments = useCallback(async ({ document_ids }) => {
    try {
      // Make an array of GET requests for each document ID
      const promises = document_ids.map((document_id) =>
        axios.get(`${apiBaseURL}/${endpoint_prefix}/download/${document_id}`, { responseType: 'blob' })
      );

      // Wait for all requests to complete
      const responses = await Promise.all(promises);

      // Loop through the responses and trigger the download for each document
      responses.forEach((res) => {
        const file_name = res.headers['content-disposition'].match(/filename="(.+)"/)[1];
        const file_blob = res.data;

        const url = window.URL.createObjectURL(new Blob([file_blob]));
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', file_name);

        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (err) {
      // Handle error
      notify.error();
    }
  }, []);

  const menuItems = useMemo(() => {
    return [
      <MenuItem
        icon="rotate-right"
        label="refresh"
        onClick={() => getDocuments()}
      />
    ];
  }, []);

  if (!tab_id && tab_id !== null) return null;

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionBox, setSelectionBox] = useState(null);

  const handleMouseDown = (e) => {
    if (e.button !== 0) return;

    const rect = scrollableContainerRef.current.getBoundingClientRect();
    setSelectionBox({
      startX: e.clientX - rect.left,
      startY: e.clientY - rect.top,
      endX: e.clientX - rect.left,
      endY: e.clientY - rect.top
    });
    setIsSelecting(true);
    setSelectedDocuments([]); // Réinitialise la sélection
  };

  const handleMouseMove = (e) => {
    if (isSelecting && selectionBox) {
      const rect = scrollableContainerRef.current.getBoundingClientRect();
      const newSelectionBox = {
        ...selectionBox,
        endX: e.clientX - rect.left,
        endY: e.clientY - rect.top
      };
      setSelectionBox(newSelectionBox);

      // Mettre à jour la sélection en temps réel
      const { startX, startY, endX, endY } = newSelectionBox;

      const selected = documents.filter((doc, idx) => {
        const docElement = document.getElementById(`document-${idx}`);
        if (!docElement) return false;

        const docRect = docElement.getBoundingClientRect();
        const containerRect = scrollableContainerRef.current.getBoundingClientRect();

        const docLeft = docRect.left - containerRect.left;
        const docTop = docRect.top - containerRect.top;
        const docRight = docLeft + docRect.width;
        const docBottom = docTop + docRect.height;

        const boxLeft = Math.min(startX, endX);
        const boxTop = Math.min(startY, endY);
        const boxRight = Math.max(startX, endX);
        const boxBottom = Math.max(startY, endY);

        return docRight > boxLeft && docLeft < boxRight && docBottom > boxTop && docTop < boxBottom;
      });

      setSelectedDocuments(selected);
    }
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    setSelectionBox(null);
  };

  const header_height = !with_filters ? 90 : 0;

  return (
    <>
      <Modals
        downloadDocuments={downloadDocuments}
        getRevisions={getRevisions}
        documentToConsult={documentToConsult}
        setDocumentToConsult={setDocumentToConsult}
        documentCommentsToConsult={documentCommentsToConsult}
        setDocumentCommentsToConsult={setDocumentCommentsToConsult}
        editDocument={editDocument}
        documentToEdit={documentToEdit}
        setDocumentToEdit={setDocumentToEdit}
        renameDocument={renameDocument}
        documentToRename={documentToRename}
        setDocumentToRename={setDocumentToRename}
        setSelectedDocuments={setSelectedDocuments}
        createFolder={createFolder}
        folderNameDialogIsOpen={folderNameDialogIsOpen}
        setFolderNameDialogIsOpen={setFolderNameDialogIsOpen}
        uploadFiles={uploadFiles}
        uploadRevision={uploadRevision}
        restoreDocuments={restoreDocuments}
        deleteDocuments={deleteDocuments}
        trashDocuments={trashDocuments}
        contextMenuData={contextMenuData}
        setContextMenuData={setContextMenuData}
        viewTrash={viewTrash}
        read_only_mode={read_only_mode}
        getDocuments={getDocuments}
      />
      <div className="h-full w-full flex flex-col rounded-2xl border relative">
        <div className="bg-white flex flex-col rounded-t-2xl drop-shadow grow-0 shrink-0 px-3">
          <FolderPath
            setSelectedDocuments={setSelectedDocuments}
            viewTrash={viewTrash}
            setViewTrash={setViewTrash}
            folderPath={folderPath}
            setFolderPath={setFolderPath}
            isLoading={isLoading}
            getDocuments={getDocuments}
          />
          <FilterAndSearchBarForm
            totalResults={documentsTotalCount}
            menuItems={menuItems}
            withFilters={with_filters}
            filters={filters_in_bar}
            onChange={(data) => setFilters(data)}
            initialValues={initialFilterValues}
          />
        </div>
        <DropZone
          onDropFiles={uploadFiles}
          disabled={!tab_id || isUploadingDocuments}
        >
          <div
            ref={scrollableContainerRef}
            onMouseDown={(event) => {
              const isInsideDocument = event.target.closest('.document-item'); // Vérifie si l'élément cliqué est un document
              if (!isInsideDocument || event.ctrlKey || event.shiftKey) {
                // Permettre la sélection si aucune interaction directe avec un document OU utilisation de touches modifiées
                handleMouseDown(event);
              }
            }}
            onMouseMove={(event) => {
              if (isSelecting) {
                handleMouseMove(event); // Toujours gérer la sélection si elle a commencé
              }
            }}
            onMouseUp={(event) => {
              if (isSelecting) {
                handleMouseUp(event); // Toujours terminer la sélection
              }
            }}
            onContextMenu={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setContextMenuData({ top: event.clientY, left: event.clientX });
            }}
            className={`${with_filters && 'relative'} overflow-auto h-full bg-gray-100`}
          >
            {isSelecting && selectionBox && (
              <div
                style={{
                  position: 'absolute',
                  left: Math.min(selectionBox.startX, selectionBox.endX),
                  top:
                    Math.min(selectionBox.startY, selectionBox.endY) +
                    header_height +
                    scrollableContainerRef.current.scrollTop, // 90px = header height
                  width: Math.abs(selectionBox.endX - selectionBox.startX),
                  height: Math.abs(selectionBox.endY - selectionBox.startY),
                  backgroundColor: 'rgba(0, 120, 215, 0.2)',
                  border: '1px solid rgba(0, 120, 215, 0.8)'
                }}
              />
            )}
            <div className="flex flex-wrap px-4 py-3">
              {Array.isArray(documents) &&
                documents.map((document, idx) => (
                  <Document
                    read_only_mode={read_only_mode}
                    key={document._id}
                    idx={idx}
                    openFile={openFile}
                    document={document}
                    goToFolder={goToFolder}
                    setContextMenuPosition={setContextMenuData}
                    setSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    moveDocument={moveDocument}
                  />
                ))}
              {uploadingNFiles > 0 &&
                Array(uploadingNFiles)
                  .fill(0)
                  .map(() => <Skeleton key={nanoid()} />)}
              {isLoading &&
                Array(3)
                  .fill(0)
                  .map(() => <Skeleton key={nanoid()} />)}
              {!viewTrash && !read_only_mode && !folderPath.length && canEditAndDeleteDocuments && (
                <Trash
                  trash_is_empty={trash_is_empty}
                  trashId={trashId}
                  setViewTrash={setViewTrash}
                  deleteDocuments={trashDocuments}
                  isSelected={selectedDocuments.some((doc) => doc._id === trashId)}
                  setSelectedDocuments={setSelectedDocuments}
                />
              )}
              <InfiniteLoader
                fetchedCount={documents?.length}
                isLoading={isLoading}
                fetchMore={getDocuments}
                totalCount={documentsTotalCount}
              />
              <div className="w-full h-96 opacity-0" />
            </div>
          </div>
        </DropZone>
        {!read_only_mode && (
          <div>
            <UploadButton onUpload={(event) => uploadFiles(event.target.files)}>
              <Fab
                disabled={viewTrash || read_only_mode}
                size="medium"
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  backgroundColor: 'rgb(51 65 85)',
                  color: 'white'
                }}
              >
                <FAIcon
                  collection="fas"
                  icon="cloud-arrow-up"
                  size="md"
                />
              </Fab>
            </UploadButton>
          </div>
        )}
      </div>
    </>
  );
}

function Modals({
  downloadDocuments,
  getDocuments,
  getRevisions,
  documentToConsult,
  setDocumentToConsult,
  documentCommentsToConsult,
  setDocumentCommentsToConsult,
  editDocument,
  documentToEdit,
  setDocumentToEdit,
  renameDocument,
  documentToRename,
  setDocumentToRename,
  setSelectedDocuments,
  createFolder,
  folderNameDialogIsOpen,
  setFolderNameDialogIsOpen,
  uploadRevision,
  uploadFiles,
  restoreDocuments,
  trashDocuments,
  deleteDocuments,
  contextMenuData,
  setContextMenuData,
  viewTrash,
  read_only_mode
}) {
  return (
    <>
      <Portal>
        <DocumentInfo
          getRevisions={getRevisions}
          document={documentToConsult}
          setDocument={setDocumentToConsult}
          setDocumentToEdit={setDocumentToEdit}
        />
      </Portal>
      <Portal>
        <DocumentComments
          document={documentCommentsToConsult}
          setDocument={setDocumentCommentsToConsult}
          getRevisions={getRevisions}
        />
      </Portal>
      <Portal>
        <DocumentEditor
          editDocument={editDocument}
          document={documentToEdit}
          setDocument={setDocumentToEdit}
        />
      </Portal>
      <Portal>
        <RenameDocumentDialog
          renameDocument={renameDocument}
          document={documentToRename}
          setDocument={setDocumentToRename}
        />
      </Portal>
      <Portal>
        <CreateFolderDialog
          setSelectedDocuments={setSelectedDocuments}
          createFolder={createFolder}
          isOpen={folderNameDialogIsOpen}
          setIsOpen={setFolderNameDialogIsOpen}
        />
      </Portal>
      <Portal>
        <ContextFileMenu
          getDocuments={getDocuments}
          downloadDocuments={downloadDocuments}
          read_only_mode={read_only_mode}
          viewTrash={viewTrash}
          uploadFiles={uploadFiles}
          uploadRevision={uploadRevision}
          restoreDocuments={restoreDocuments}
          deleteDocuments={deleteDocuments}
          trashDocuments={trashDocuments}
          contextMenuData={contextMenuData}
          setContextMenuData={setContextMenuData}
          setDocumentToRename={setDocumentToRename}
          setDocumentToEdit={setDocumentToEdit}
          setDocumentToConsult={setDocumentToConsult}
          setDocumentCommentsToConsult={setDocumentCommentsToConsult}
          setFolderNameDialogIsOpen={setFolderNameDialogIsOpen}
        />
      </Portal>
    </>
  );
}
