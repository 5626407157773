import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import useAsyncDispatch from 'hooks/useAsyncDispatch.js';
import { useAuth } from 'hooks/useAuth.js';
import { useConfiguration } from 'hooks/useConfiguration';
import useDebouncedState from 'hooks/useDebouncedState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getInitialValuesFromFilters, getTicketFilters, LIMIT, ticketSortingOrder } from './baseFilters.js';
import { getBookmarks } from './utils.js';
import {
  createFilterSetsManager,
  ticketMenuItems,
  fetchTickets,
  generateFilters,
  useFilteredBookmarks
} from './utilsFun.js';
import PreviewRenderer from './Virtuoso/PreviewRenderer.js';
import FAIcon from 'components/ui/FAIcon.js';
import TrashFile from './TrashFile.js';

const TicketFile = ({ scheduler, userMaintenance, calendar }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfiguration();
  const { dispatch } = useAsyncDispatch();
  const customFields = useSelector((store) => store.fieldSections).ticket;

  // Initialisation des bookmarks et des états de filtres
  const [data, setData] = useState(() => ({ tickets: null, count: null }));
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isTrashOpen, setIsTrashOpen] = useState(false);
  const [bookmarkState, setBookmarkState] = useState({
    bookmarks: getBookmarks(scheduler, calendar, auth),
    isLoading: false
  });

  const showableFilters = getTicketFilters({
    configuration: config,
    userMaintenance,
    calendar,
    scheduler,
    customFields,
    deleted: false
  });

  // Gestion de l'état des filtres avec debounce
  const [initialValues, setInitialValues] = useState(
    getInitialValuesFromFilters({ filters: showableFilters, deleted: false })
  );
  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues, 500);

  const [filterSetsManager, setFilterSetsManager] = useState(createFilterSetsManager(appliedFilters));

  useFilteredBookmarks(calendar, appliedFilters, setBookmarkState, bookmarkState);
  const stableFetchTickets = useCallback(
    fetchTickets({ auth, setData, dispatch, setSkip, skip, limit: LIMIT, setHasMore }),
    [auth, setData, dispatch, setSkip, skip, LIMIT, setHasMore]
  );

  // Fetch les tickets lors du select d'un filtre
  useEffect(() => {
    stableFetchTickets(debouncedAppliedFilters, false);
  }, [debouncedAppliedFilters]);

  // Création des éléments de menu et des composants de filtre
  const menuItems = ticketMenuItems({
    t,
    fetchTickets: stableFetchTickets,
    appliedFilters,
    setIsTrashOpen,
    count: data.count
  });

  const filters = useMemo(
    () => generateFilters(filterSetsManager.orderFilters, showableFilters),
    [filterSetsManager, showableFilters]
  );

  const endReached = useCallback(() => {
    if (hasMore) {
      stableFetchTickets(appliedFilters, true);
    }
  }, [hasMore, stableFetchTickets, appliedFilters]);

  return (
    <>
      <TrashFile
        setIsModalOpen={setIsTrashOpen}
        isModalOpen={isTrashOpen}
      />
      <div className="bg-white m-2 mb-0 bb-none h-full rounded-xl rounded-b-none overflow-hidden flex flex-col">
        <FilterAndSearchBarForm
          withFilters
          totalResults={data?.count}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          appliedFilters={appliedFilters}
          filterSetsManager={filterSetsManager}
          setFilterSetsManager={setFilterSetsManager}
          onChange={setAppliedFilters}
          menuItems={menuItems}
          filters={filters}
          dataFilters={showableFilters.map((filter) => ({
            label: filter.props.label,
            key: filter.props.path
          }))}
          sortingOrders={ticketSortingOrder}
          bookmarkState={bookmarkState}
        />
        {data.tickets === null ? (
          <SkeletonTicket />
        ) : !data?.tickets?.length ? (
          <TemplateNoTickets scheduler={scheduler} />
        ) : (
          <PreviewRenderer
            entity="tickets"
            data={data.tickets}
            endReached={endReached}
            hasMore={hasMore}
            appliedFilters={appliedFilters}
          />
        )}
      </div>
    </>
  );
};

export default TicketFile;

export const TemplateNoTickets = ({ scheduler }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center h-[65%] text-gray-600">
      <FAIcon
        className="mr-3"
        collection="fas"
        icon="files"
        size="medium"
      />
      <h1 className="text-xl font-semibold">
        {t(scheduler ? 'entityTicketnoResultLabel1' : 'entityTicketnoResultLabel2')}
      </h1>
    </div>
  );
};

export const SkeletonTicket = () => {
  return [1, 2, 3, 4, 5].map((_, i) => (
    <div
      key={i}
      className="w-full flex flex-col gap-3 h-36 border-b p-4"
    >
      <div className="w-full flex items-center justify-between rounded-xl">
        <span className="w-44 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
        <div className="flex items-center gap-2">
          <span className="w-4 h-4 animate-pulse bg-gray-300 rounded"></span>
          <span className="w-28 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
        </div>
      </div>
      <div className="w-full flex items-center justify-between rounded-xl">
        <span className="w-32 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
        <span className="w-36 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
      </div>
      <div className="w-full flex items-center justify-between rounded-xl">
        <span className="w-full h-8 animate-pulse bg-gray-300 rounded-xl"></span>
      </div>
      <div className="w-full flex items-center justify-between rounded-xl">
        <span className="w-40 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
        <span className="w-40 h-3 animate-pulse bg-gray-300 rounded-xl"></span>
      </div>
    </div>
  ));
};
