import { createElement, useEffect, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import NotEmptyIndicator from './NotEmptyIndicator';
import { Popover as MuiPopover } from '@material-ui/core';
import { useField } from 'frmx';
import { getColor } from 'router/tickets/utils';

export default function EntitySelectCheckbox({ label, entity, path, typePath, className, entityProps, color }) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [is_open_popover_filter, set_is_open_popover_filter] = useState(false);

  const { value, setValue } = useField(typePath);
  const { setValue: setListValue } = useField(path);

  const {
    activeIconColor,
    inactiveIconColor,
    inactiveTextColor,
    activeTextColor,
    inactiveBorderColor,
    activeBorderColor
  } = getColor(color);

  const handle_click = (event) => {
    event.stopPropagation();
    set_is_open_popover_filter(!is_open_popover_filter);
    setAnchorEl(event.currentTarget);
    if (!is_open_popover_filter && !value.includes('maintenance')) {
      setValue([...value, 'maintenance']);
    }
  };
  const toggleValue = (item) => {
    if (value.includes(item)) {
      setValue(value.filter((val) => val !== item));
    } else {
      setValue([...value, item]);
    }
  };

  const isChecked = value.includes('maintenance');

  useEffect(() => {
    if (!isChecked) {
      setListValue([]);
    }
  }, [isChecked]);

  return (
    <>
      <div
        onClick={() => toggleValue('maintenance')}
        className={`cursor-pointer border relative ${isChecked ? activeBorderColor : inactiveBorderColor} ${
          isChecked ? activeTextColor : inactiveTextColor
        } hover:bg-gray-50 transition inline-flex text-gray-500 items-center pl-2 h-8 rounded-full text-xs font-medium`}
      >
        <FAIcon
          collection={isChecked ? 'fas' : 'fal'}
          icon={isChecked ? 'circle-check' : 'circle'}
          size="small"
          className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
        />
        <NotEmptyIndicator
          path={path}
          showIndicator
        />
        {t(label)}
        <div
          onClick={handle_click}
          className="h-[30px] w-[30px] ml-1 hover:bg-gray-200 transition rounded-full flex justify-center items-center"
        >
          <FAIcon
            icon="caret-down"
            collection="fas"
            size="xs"
            className="text-gray-600"
          />
        </div>
      </div>
      <MuiPopover
        open={is_open_popover_filter}
        transitionDuration={200}
        anchorEl={anchorEl}
        onClose={() => set_is_open_popover_filter(!is_open_popover_filter)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -150
        }}
      >
        <div className={classNames('z-40 h-[450px] w-[30vw] min-w-[400px] max-w-[550px]', className)}>
          {createElement(entity, { filter: true, picker: true, pickerField: path, ...entityProps })}
        </div>
      </MuiPopover>
    </>
  );
}
