import DialogMUI from 'components/DialogMUI';
import { Field, Form, Submit } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function CreateFolderDialog({
  isOpen,
  setIsOpen,
  createFolder,
  setSelectedDocuments
}) {
  const { t } = useTranslation();

  return (
    <>
      <DialogMUI
        isOpen={isOpen}
        title={t('newFolderTitle')}
        icon="pen-to-square"
        onClose={() => setIsOpen(false)}
        className="w-[450px] z-[1400]"
      >
        <Form
          initialValues={{ name: '' }}
          onSubmit={(values) => [
            createFolder(values.name).then(({ data }) => setSelectedDocuments([data.document])),
            setIsOpen(false)
          ]}
          className="mt-2 flex flex-col"
        >
          <Field
            path="name"
            className="pl-2 my-4 h-10 text-base shadow-sm focus:ring-sky-500 focus:border-sky-500 border-2 border-gray-300 rounded-md"
          >
            <input />
          </Field>
          <div className="flex justify-end space-x-2 mb-3">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </button>
            <Submit>
              <button className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                {t('create')}
              </button>
            </Submit>
          </div>
        </Form>
      </DialogMUI>
    </>
  );
}
