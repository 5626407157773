import { IconButton } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getColor } from 'router/tickets/utils';
import { truncateText } from 'utils/uiUtils';

export const ActionCustomFilterSet = ({ label, onEdit, onDelete, isChecked, selectionFilter }) => {
  const { t } = useTranslation();

  const { activeIconColor, inactiveIconColor } = getColor('sky');

  return (
    <div
      className="h-12 px-2 flex min-w-[270px] items-center gap-2 justify-between rounded-lg bg-white cursor-pointer hover:bg-gray-100"
      onClick={selectionFilter}
    >
      <label className="flex items-center gap-1 cursor-pointer">
        <FAIcon
          collection={isChecked ? 'fas' : 'fal'}
          icon={isChecked ? 'circle-check' : 'circle'}
          size="medium"
          className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
        />

        <p className="text-sm text-gray-800 font-medium">{truncateText(label === 'defaultFilterSet' ? t(label) : label)}</p>
      </label>
      {label !== 'defaultFilterSet' && (
        <div className="flex gap-2">
          <IconButton
            className=" !p-1.5 text-gray-500 hover:text-blue-500 hover:bg-gray-200 transition"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            size="small"
            aria-label="Edit filter"
          >
            <FAIcon
              collection="fas"
              icon="edit"
              size="small"
            />
          </IconButton>
          <IconButton
            className="!p-1.5 text-gray-500 hover:text-red-500 hover:bg-gray-200 transition"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            size="small"
            aria-label="Delete filter"
          >
            <FAIcon
              collection="fas"
              icon="trash"
              size="small"
            />
          </IconButton>
        </div>
      )}
    </div>
  );
};
