import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import ObjectID from 'bson-objectid';
import FAIcon from 'components/ui/FAIcon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LIMIT } from 'router/tickets/baseFilters';
import { truncateText } from 'utils/uiUtils';

const FILTER_KEYS_TO_EXCLUDE = ['deleted', 'types', 'search', 'bookmarks', 'sort', 'order', 'limit', 'skip'];

const getOrderedFilterSet = (defaultFilterSet, selectedFilterSet) => {
  const selectedKeys = selectedFilterSet.filters.map(({ key }) => key);
  const availableFilters = defaultFilterSet.filters.filter(({ key }) => !FILTER_KEYS_TO_EXCLUDE.includes(key));
  return {
    title: selectedFilterSet.title,
    filters: [
      ...selectedFilterSet.filters.filter(({ key }) => !FILTER_KEYS_TO_EXCLUDE.includes(key)),
      ...availableFilters.filter(({ key }) => !selectedKeys.includes(key))
    ]
  };
};

const createFilterObject = (key, val, op) => ({ key, val, op });

const FilterDialog = ({ open, onClose, onSave, defaultFilterSet, selectedFilterSet, isCreate, dataFilters }) => {
  const { t } = useTranslation();
  const initialTitle = selectedFilterSet.title || '';

  const [filterSet, setFilterSet] = useState(getOrderedFilterSet(defaultFilterSet, selectedFilterSet));
  const [selectedItems, setSelectedItems] = useState([]);
  const [title, setTitle] = useState(initialTitle);
  const [isFirstSaveAttempted, setIsFirstSaveAttempted] = useState(false);

  useEffect(() => {
    setFilterSet(getOrderedFilterSet(defaultFilterSet, selectedFilterSet));
    setSelectedItems(isCreate ? [] : selectedFilterSet.filters);
    setTitle(isCreate ? '' : initialTitle);
    setIsFirstSaveAttempted(false);
  }, [isCreate, selectedFilterSet, open, defaultFilterSet]);

  const toggleFilterSelection = (filter) => {
    setSelectedItems((prev) => {
      const updatedItems = prev.some(({ key }) => key === filter.key)
        ? prev.filter(({ key }) => key !== filter.key)
        : [...prev, filter];
      return filterSet.filters.filter(({ key }) => updatedItems.some((item) => item.key === key));
    });
  };

  const handleSave = () => {
    setIsFirstSaveAttempted(true);
    if (!title) return;

    onSave({
      ticketFilters: {
        title: title,
        filters: [
          ...selectedItems,
          createFilterObject('types', 'intervention', 'equals'),
          createFilterObject('sort', 'creation', 'equals'),
          createFilterObject('order', 'desc', 'equals'),
          createFilterObject('limit', LIMIT, 'equals'),
          createFilterObject('skip', 0, 'equals'),
          createFilterObject('search', '', 'fuzzy'),
          createFilterObject('deleted', false, 'equals'),
          createFilterObject('bookmarks', [], 'in')
        ],
        _id: isCreate ? new ObjectID().toString() : selectedFilterSet._id
      }
    });
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setFilterSet((items) => {
        const [oldIndex, newIndex] = [
          items.filters.findIndex((i) => i.key === active.id),
          items.filters.findIndex((i) => i.key === over.id)
        ];
        const newFilterSet = arrayMove(items.filters, oldIndex, newIndex);
        setSelectedItems((prev) => newFilterSet.filter((item) => prev.some(({ key }) => key === item.key)));
        return {
          title: items.title,
          filters: newFilterSet
        };
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{truncateText(isCreate ? t('createSetFilter') : `${t('update')} ${title}`)}</DialogTitle>
      <DialogContent
        dividers
        className="flex flex-col gap-3"
      >
        <TextField
          label={t('Titre du set de filtre')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          required
          error={!title && isFirstSaveAttempted}
          helperText={!title && isFirstSaveAttempted ? t('requiredTitle') : ''}
        />
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={filterSet.filters.map((f) => f.key)}>
            <div className="flex flex-wrap gap-3 items-start justify-center m-8">
              {filterSet.filters.map((filter) => (
                <DraggableFilter
                  key={filter.key}
                  filter={filter}
                  label={dataFilters.find((f) => f.key === filter.key)?.label}
                  isSelected={selectedItems.some(({ key }) => key === filter.key)}
                  toggleFilterSelection={() => toggleFilterSelection(filter)}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="outlined"
          onClick={handleSave}
          color="primary"
          disabled={!title && isFirstSaveAttempted}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DraggableFilter = ({ label, filter, isSelected, toggleFilterSelection }) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: filter.key });
  const [clickStartTime, setClickStartTime] = useState(null);

  const handleMouseUp = () => Date.now() - clickStartTime < 250 && toggleFilterSelection(filter);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    willChange: 'transform',
    minWidth: '150px',
    maxWidth: '200px',
    borderRadius: '99px',
    borderWidth: '1px',
    padding: '8px 14px',
    position: 'relative',
    textAlign: 'center',
    fontSize: '0.875rem',
    cursor: 'pointer',
    fontWeight: '300',
    backgroundColor: isSelected ? '#1e3a8a' : 'rgba(59, 130, 246, 0.1)',
    color: isSelected ? '#ffffff' : '#1e40af'
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      onMouseDown={() => setClickStartTime(Date.now())}
      onMouseUp={handleMouseUp}
    >
      {t(label)}
      {isSelected && (
        <FAIcon
          collection="fas"
          icon="circle-check"
          size="sm"
          className="absolute -top-2 -right-2 bg-blue-800 rounded-full !px-0 border-2 border-white"
        />
      )}
    </div>
  );
};

export default FilterDialog;
