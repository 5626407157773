import { GroupedVirtuoso } from 'react-virtuoso';
import { format, isToday, isYesterday, isThisMonth, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useUI } from 'hooks/useUI';
import TicketPreviewNew from './TicketPreviewNew';
import { dateToLocalFormat } from 'utils/dates';
import { useMemo } from 'react';

const capitalizeFirstLetter = (string) => {
  if (!string) return string; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function GroupedVirtuosoNew({ groupRef, data, endReached, Footer, hasMore, appliedFilters }) {
  const { t } = useTranslation();
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  const sort_key = appliedFilters.sort;
  const search_term = appliedFilters.search;

  const { groupNames, groupCounts, flatElements } = useMemo(() => {
    // Function to organize tickets by creation date into categories by returning a object
    const GroupItemsByDate = (arrTicket) => {
      const grouped = {
        today: [],
        yesterday: [],
        dayNames: {},
        datesOfMonth: {},
        monthsOfYear: {},
        all: []
      };

      if (Boolean(search_term)) {
        grouped.all = arrTicket;
        return grouped;
      }

      arrTicket.forEach((ticket) => {
        const createdAt = new Date(ticket.cat);
        const updatedAt = new Date(ticket.uat);
        const sortDate = sort_key === 'updated_at' ? updatedAt : createdAt;

        if (isToday(sortDate)) {
          grouped.today.push(ticket);
        } else if (isYesterday(sortDate)) {
          grouped.yesterday.push(ticket);
        } else if (sortDate >= subDays(new Date(), 7)) {
          const dayName = capitalizeFirstLetter(format(sortDate, 'EEEE', { locale }));
          if (!grouped.dayNames[dayName]) {
            grouped.dayNames[dayName] = [];
          }
          grouped.dayNames[dayName].push(ticket);
        } else if (isThisMonth(sortDate)) {
          const dayOfMonth = capitalizeFirstLetter(format(sortDate, 'd/MM/yyyy', { locale }));
          if (!grouped.datesOfMonth[dayOfMonth]) {
            grouped.datesOfMonth[dayOfMonth] = [];
          }
          grouped.datesOfMonth[dayOfMonth].push(ticket);
        } else {
          const monthOfYear = capitalizeFirstLetter(format(sortDate, 'MMMM yyyy', { locale }));
          if (!grouped.monthsOfYear[monthOfYear]) {
            grouped.monthsOfYear[monthOfYear] = [];
          }
          grouped.monthsOfYear[monthOfYear].push(ticket);
        }
      });

      return grouped;
    };

    // Get the name of the group to display and translate them for example : today , june , 2022 ect..
    const getGroupNames = (grouped) => {
      const groupNames = [];

      if (Boolean(search_term)) return groupNames;

      if (grouped.today.length > 0) groupNames.push(t('Today'));
      if (grouped.yesterday.length > 0) groupNames.push(t('yesterday'));

      for (const dayName in grouped.dayNames) {
        if (grouped.dayNames[dayName].length > 0) {
          groupNames.push(dayName);
        }
      }

      for (const date in grouped.datesOfMonth) {
        if (grouped.datesOfMonth[date].length > 0) {
          groupNames.push(date);
        }
      }

      for (const month in grouped.monthsOfYear) {
        if (grouped.monthsOfYear[month].length > 0) {
          groupNames.push(month);
        }
      }
      return groupNames;
    };

    const groupedElements = GroupItemsByDate(data /*,db*/);

    const groupNames = getGroupNames(groupedElements);

    // Create a array with the counts of tickets of each categorie and filter out the empty categorie
    const groupCounts = Boolean(search_term)
      ? [groupedElements.all.length]
      : [
          groupedElements.today.length,
          groupedElements.yesterday.length,
          ...Object.values(groupedElements.dayNames).map((group) => group.length),
          ...Object.values(groupedElements.datesOfMonth).map((group) => group.length),
          ...Object.values(groupedElements.monthsOfYear).map((group) => group.length)
        ].filter((count) => count > 0); // Filter out empty counts

    // Get the flattened list of tickets ordered from most recent (today) to less recent (monthly groups & yearly groups)
    const flatElements = [
      ...groupedElements.all,
      ...groupedElements.today,
      ...groupedElements.yesterday,
      ...Object.values(groupedElements.dayNames).flat(),
      ...Object.values(groupedElements.datesOfMonth).flat(),
      ...Object.values(groupedElements.monthsOfYear).flat()
    ]; // Ensure that flatElements matches the order of groupCounts

    return {
      groupNames,
      groupCounts,
      flatElements
    };
  }, [sort_key, search_term, data]);

  return (
    <GroupedVirtuoso
      groupCounts={groupCounts} // Number of items in each group
      ref={groupRef}
      groupContent={(index) => (Boolean(search_term) ? null : <GroupHeader text={groupNames[index]} />)}
      itemContent={(index) => {
        const ticket = flatElements[index];
        return (
          <>
            {Boolean(search_term) && <GroupHeader text={dateToLocalFormat(ticket.cat)} />}
            <TicketPreviewNew ticket={ticket} />
          </>
        );
      }}
      style={{ overflowX: 'hidden' }}
      endReached={endReached}
      components={{ Footer: () => <Footer hasMore={hasMore} /> }}
    />
  );
}

function GroupHeader({ text }) {
  return <div className="bg-[#f5f5f5] py-1 pl-2 font-semibold">{text}</div>;
}
